import Vue from 'vue';
import Router from 'vue-router';
import {getCookie,setCookie, getUrlParams, oauthbycode,weixinStorage,vuexStorage,oauthBase,openidStorage} from '@/api/login'
import store from "@/store";
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


import async from "async";
// const  async  =  require('async')
Vue.use(Router);
const Shop = () => import(/* webpackChunkName: "selectnum" */'@/view/shop')
const Selectnum = () => import(/* webpackChunkName: "shop" */'@/view/shop/selectnum.vue')
const Selectprod = () => import(/* webpackChunkName: "shop" */'@/view/shop/selectprod.vue')
const Address = () => import(/* webpackChunkName: "shop" */'@/view/shop/address.vue')
const Ricered = () => import(/* webpackChunkName: "shop" */'@/view/shop/red.vue')

// beign--电信靓号活动
const Prodctc = () => import(/* webpackChunkName: "shopctc" */'@/view/shopctc/selectprod.vue')
const Numctc = () => import(/* webpackChunkName: "shopctc" */'@/view/shopctc/selectnum.vue')
// const Fortune = () => import(/* webpackChunkName: "shopctc" */'@/view/shopctc/fortune.vue')
const Addctc = () => import(/* webpackChunkName: "addressctc" */'@/view/shopctc/address.vue')
// end--电信靓号活动



// beign--numtail电信靓号活动
const Numstart = () => import(/* webpackChunkName: "numtail" */'@/view/numtail/numstart.vue')
const Numtail = () => import(/* webpackChunkName: "numtail" */'@/view/numtail/selectnum.vue')
const Prodtail = () => import(/* webpackChunkName: "numtail" */'@/view/numtail/selectprod.vue')
const Addtail = () => import(/* webpackChunkName: "numtail" */'@/view/numtail/address.vue')
// end--numtail电信靓号活动



const Bill = () => import(/* webpackChunkName: "bill" */'@/view/bill')
const Prodused = () => import(/* webpackChunkName: "prodused" */'@/view/prodused')
const Payhistory = () => import(/* webpackChunkName: "payhistory" */'@/view/payhistory')
// begin--详单
const Detail = () => import(/* webpackChunkName: "detail" */'@/view/detail')
const Interim = () => import(/* webpackChunkName: "detail" */'@/view/detail/interim.vue')
const Query = () => import(/* webpackChunkName: "query" */'@/view/query')
const Manage = () => import(/* webpackChunkName: "manage" */'@/view/manage')
const Voice = () => import(/* webpackChunkName: "voice" */'@/view/detail/voice.vue')
const Mess = () => import(/* webpackChunkName: "mess" */'@/view/detail/mess.vue')
const Mms = () => import(/* webpackChunkName: "mess" */'@/view/detail/mms.vue')
const Web = () => import(/* webpackChunkName: "mess" */'@/view/detail/web.vue')
// end--详单
const Remind = () => import(/* webpackChunkName: "remind" */'@/view/remind')
const Home = () => import(/* webpackChunkName: "home" */'@/view/home')
const Fee = () => import(/* webpackChunkName: "fee" */'@/view/fee')
const Login = () => import(/* webpackChunkName: "login" */'@/view/login')
const loginAll = () => import(/* webpackChunkName: "login" */'@/view/login/loginAll.vue')
const Code = () => import(/* webpackChunkName: "login" */'@/view/login/code.vue')
const Reset = () => import(/* webpackChunkName: "login" */'@/view/login/reset.vue')
const Forget = () => import(/* webpackChunkName: "login" */'@/view/login/forget.vue')
const User = () => import(/* webpackChunkName: "user" */'@/view/user')
const MyProd = () => import(/* webpackChunkName: "user" */'@/view/user/myProd')
const UserRes = () => import(/* webpackChunkName: "user" */'@/view/user/userRes')
const Cart = () => import(/* webpackChunkName: "cart" */'@/view/cart')
const Goods = () => import(/* webpackChunkName: "goods" */'@/view/goods')
const Temporary = () => import(/* webpackChunkName: "temporary" */'@/view/temporary')
const Baseinfo = () => import(/* webpackChunkName: "temporary" */'@/view/baseinfo')
const Charge = () => import(/* webpackChunkName: "charge" */'@/view/charge/')
const Chargedis = () => import(/* webpackChunkName: "chargedis" */'@/view/chargedis/')
const Super = () => import(/* webpackChunkName: "charge" */'@/view/charge/super.vue')

const About = () => import(/* webpackChunkName: "about" */'@/view/login/About')


const Gettype = () => import(/* webpackChunkName: "activate" */'@/view/activate/gettype.vue/')
const Actorder = () => import(/* webpackChunkName: "activate" */'@/view/activate/')
const Actfront = () => import(/* webpackChunkName: "activate" */'@/view/activate/front.vue')
const Actback = () => import(/* webpackChunkName: "activate" */'@/view/activate/back.vue')
const Acthand = () => import(/* webpackChunkName: "activate" */'@/view/activate/hand.vue')
const  Tmallgroupphoto = () => import(/* webpackChunkName: "transfer" */'@/view/activate/tmallgroupphoto.vue')
const  Actgroupphoto = () => import(/* webpackChunkName: "transfer" */'@/view/activate/groupphoto.vue')

const Actvideo = () => import(/* webpackChunkName: "activate" */'@/view/activate/video.vue')
const Actcharge = () => import(/* webpackChunkName: "activate" */'@/view/activate/charge')

const Rechargeorder = () => import(/* webpackChunkName: "charge" */'@/view/charge/chargeorder')
// begin——天猫
const Acttmallfront = () => import(/* webpackChunkName: "tmallactivate" */'@/view/activate/tmallfront.vue')
const Acttmallback = () => import(/* webpackChunkName: "tmallactivate" */'@/view/activate/tmallback.vue')
const Acttmallhand = () => import(/* webpackChunkName: "tmallactivate" */'@/view/activate/tmallhand.vue')
const Acttmallvideo = () => import(/* webpackChunkName: "tmallactivate" */'@/view/activate/tmallvideo.vue')
// end——天猫

const Register = () => import(/* webpackChunkName: "rice" */'@/view/rice/register.vue')
const Ricecard = () => import(/* webpackChunkName: "rice" */'@/view/rice/card.vue')
const Riceprod = () => import(/* webpackChunkName: "rice" */'@/view/rice/selectprod.vue')
const Ricenum = () => import(/* webpackChunkName: "rice" */'@/view/rice/selectnum.vue')
const Ordercharge = () => import(/* webpackChunkName: "rice" */'@/view/rice/ordercharge.vue')
const Club = () => import(/* webpackChunkName: "rice" */'@/view/rice/club.vue')
const Custcharge = () => import(/* webpackChunkName: "rice" */'@/view/rice/custcharge.vue')
const Shell = () => import(/* webpackChunkName: "rice" */'@/view/rice/shell.vue')
const Qifest = () => import(/* webpackChunkName: "rice" */'@/view/rice/qifest.vue')
const Riceorder = () => import(/* webpackChunkName: "rice" */'@/view/rice/order.vue')
const Riceordersea = () => import(/* webpackChunkName: "rice" */'@/view/rice/ordersea.vue')

const Actricefront = () => import(/* webpackChunkName: "rice" */'@/view/rice/ricefront.vue')
const Actriceback = () => import(/* webpackChunkName: "rice" */'@/view/rice/riceback.vue')
const Actricevideo = () => import(/* webpackChunkName: "rice" */'@/view/rice/ricevideo.vue')




const Replaceorder = () => import(/* webpackChunkName: "replace" */'@/view/replace/')
const Replacefront = () => import(/* webpackChunkName: "replace" */'@/view/replace/front.vue')
const Replaceback = () => import(/* webpackChunkName: "replace" */'@/view/replace/back.vue')
const Replacevideo = () => import(/* webpackChunkName: "replace" */'@/view/replace/video.vue')



// begin-图片压缩
const Img = () => import(/* webpackChunkName: "img" */'@/view/img/')
// end-图片压缩

// begin -名下号码查询

const  Idqueryorder = () => import(/* webpackChunkName: "idquery" */'@/view/rep/')
const  Idqueryfront = () => import(/* webpackChunkName: "idquery" */'@/view/rep/front.vue')
const  Idqueryback = () => import(/* webpackChunkName: "idquery" */'@/view/rep/back.vue')
const  Idqueryvideo = () => import(/* webpackChunkName: "idquery" */'@/view/rep/video.vue')
const  Idquerylist = () => import(/* webpackChunkName: "idquery" */'@/view/rep/list.vue')
// end -名下号码查询

// begin -过户

// const  TransferOrder = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/')
// const  TransferFront = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/front.vue')
// const  TransferBack = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/back.vue')
// const  Transfergroupphoto = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/groupphoto.vue')
// const  TransferVideo = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/video.vue')
// const  NewTransferIndex = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/newUser/index.vue')
// const  NewTransferFront = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/newUser/front')
// const  NewTransferBack= () => import(/* webpackChunkName: "transfer" */'@/view/transfer/newUser/back.vue')
// const  NewTransferVideo = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/newUser/video.vue')
// const  Idquerylist = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/list.vue')
// end -过户



// begin -名下号码查询

const  Realcheck = () => import(/* webpackChunkName: "realname" */'@/view/realname/')
const  Realfront = () => import(/* webpackChunkName: "realname" */'@/view/realname/front.vue')
const  Realback = () => import(/* webpackChunkName: "realname" */'@/view/realname/back.vue')
const  Realhand = () => import(/* webpackChunkName: "realname" */'@/view/realname/hand.vue')
const  Realvideo = () => import(/* webpackChunkName: "realname" */'@/view/realname/video.vue')
// const  Idquerylist = () => import(/* webpackChunkName: "realname" */'@/view/realname/list.vue')


// beigin--大流量卡
// const Shop = () => import(/* webpackChunkName: "flow" */'@/view/shop')
const Flowprod = () => import(/* webpackChunkName: "flow" */'@/view/flow/selectprod.vue')
const Flowtnum = () => import(/* webpackChunkName: "flow" */'@/view/flow/selectnum.vue')
const Flowadd = () => import(/* webpackChunkName: "flow" */'@/view/flow/address.vue')
// end--大流量卡




// begin ----  补换卡

const  Cardorder = () => import(/* webpackChunkName: "changecd" */'@/view/changecd/')
const  Cardfront = () => import(/* webpackChunkName: "changecd" */'@/view/changecd/front.vue')

const  Cardback = () => import(/* webpackChunkName: "changecd" */'@/view/changecd/back.vue')
const  Cardvideo = () => import(/* webpackChunkName: "changecd" */'@/view/changecd/video.vue')
const  ContactAddress = () => import(/* webpackChunkName: "changecd" */'@/view/changecd/contactAddress')
// end ----  补换卡




// begin ----  销户

// const Offorder = () => import(/* webpackChunkName: "off" */'@/view/off/index')
// const BindInfo = () => import(/* webpackChunkName: "off" */'@/view/off/bindInfo')
// const  Offfront = () => import(/* webpackChunkName: "off" */'@/view/off/front.vue')
// const  Offback = () => import(/* webpackChunkName: "off" */'@/view/off/back.vue')
// const  Offvideo = () => import(/* webpackChunkName: "off" */'@/view/off/video.vue')
// const  Offcharge = () => import(/* webpackChunkName: "off" */'@/view/off/offCharge.vue')
// const  Offcharge = () => import(/* webpackChunkName: "off" */'@/view/off/custcharge.vue')
// end ----  销户


// begin ----  红包
const  Red = () => import(/* webpackChunkName: "red" */'@/view/red/')
const  Redimg = () => import(/* webpackChunkName: "redimg" */'@/view/redimg/')
const  Buka = () => import(/* webpackChunkName: "redimg" */'@/view/redimg/buka.vue')
// end ----  红包




// beign--吉凶号码
const Prodfort = () => import(/* webpackChunkName: "fortune" */'@/view/fortune/selectprod.vue')
const Numfort = () => import(/* webpackChunkName: "fortune" */'@/view/fortune/selectnum.vue')
const Fortune = () => import(/* webpackChunkName: "fortune" */'@/view/fortune/fortune.vue')
const Addfort = () => import(/* webpackChunkName: "fortune" */'@/view/fortune/address.vue')
// end--吉凶号码

//手机充值
const Recharge = () => import('@/view/recharge/recharge')
const Gift = () => import('@/view/recharge/gift')
const Exchange = () => import('@/view/recharge/exchange')
const ExchangeOrder = () => import('@/view/recharge/exchangeOrder')
const PayConfirm = () => import('@/view/recharge/payConfirm')

//靓号商场
const NumberMarket = () => import('@/view/num_market/index')
const NumDetail = () => import('@/view/num_market/numDetail')
const OrderPay = () => import('@/view/num_market/orderPay')
const AddressList = () => import('@/view/num_market/addressList')
const AddressEdit= () => import('@/view/num_market/addressEdit')
const CartList= () => import('@/view/num_market/cartList')
const NumCollect= () => import('@/view/num_market/num_collect')
const Search= () => import('@/view/num_market/search')
const NumUser= () => import('@/view/num_market/numUser')
const MyOrder= () => import('@/view/num_market/order')
//精选套餐
const SelectProd= () => import('@/view/num_market/selectProd')
const SelSearch= () => import('@/view/num_market/selProdSearch')


//号码商场选号的 开卡激活
const WxActive = () => import('@/view/activate/wxtActive/index')
const WxCustInfo = () => import('@/view/activate/wxtActive/custInfo')
const WxHand = () => import('@/view/activate/wxtActive/hand')
const WxVideo = () => import('@/view/activate/wxtActive/video')

//代理商识人
const AgentRealFront = () => import('@/view/agentReal/front')
const AgentRealBack = () => import('@/view/agentReal/back')
const AgentRealVideo = () => import('@/view/agentReal/video')

//集团使用人实名认证
const GroupReal =() => import('@/view/groupReal/index')
const GroupRealFront =() => import('@/view/groupReal/front')
const GroupRealBack =() => import('@/view/groupReal/back')
const GroupRealVideo =() => import('@/view/groupReal/video')

//套餐变更
const ChangeOffer = () => import('@/view/manage/changeOffer')
const ChangePkg = () => import('@/view/manage/changePkg')
const ChangeFlow = () => import('@/view/manage/changeFlow')

//炫铃
const RingIndex = () => import('@/view/ring/index')
const RingPlay = () => import('@/view/ring/play')
const RingList = () => import('@/view/ring/list')

//新开卡激活
// const NewOpenOrder = () => import('@/view/activate/openCard/index')
// const NewOpenFront = () => import('@/view/activate/openCard/front')
// const NewOpenBack = () => import('@/view/activate/openCard/back')
// const NewOpenVideo = () => import('@/view/activate/openCard/video')
// const NewOpenNotPayFront = () => import('@/view/activate/openCard/notPayfront')
// const NewOpenNotPayback = () => import('@/view/activate/openCard/notPayback')
// const NewOpenNotPayVideo = () => import('@/view/activate/openCard/notPayvideo')
// const NewOpenCharge = () => import('@/view/activate/openCard/charge')

const ChangeCardOrder = () => import('@/view/query/changeCardOrder')
const UserTransferOrder = () => import('@/view/query/userTransferOrder')
// const Tips = () => import('@/view/activate/tips')
// const ExamineResult = () => import('@/view/realname/examineResult')
const LargeSpin = () => import('@/view/user/largeSpin.vue')


const routes = [
    // end-调试阿里
    {
        path: '*',
        redirect: '/home'
    },
    {
        path: '/buka',
        name: 'buka',
        component: Buka,
        meta: {
            title: '补卡'
        }
    },
// beign--吉凶号码
    {
        path: '/prodfort',
        name: 'prodfort',
        component: Prodfort,
        meta: {
            title: '选择套餐'
        }
    },
    {
        path: '/numfort',
        name: 'numfort',
        component: Numfort,
        meta: {
            title: '选择号码'
        }
    },
    {
        path: '/fortune',
        name: 'fortune',
        component: Fortune,
        meta: {
            title: '号码吉凶'
        }
    },
    {
        path: '/addfort',
        name: 'addfort',
        component: Addfort,
        meta: {
            title: '收件地址'
        }
    },
// end--吉凶号码
// begin-销户
//     {
//         path: '/userOffOrder',
//         name: 'userOffOrder',
//         component: Offorder,
//         meta: {
//             title: '销户',
//             snsapiBase: true
//         }
//     },
//     {
//         path: '/bindInfo',
//         name: 'bindInfo',
//         component: BindInfo,
//         meta: {
//             title: '信息绑定',
//             snsapiBase: true
//         }
//     },
//     {
//         path: '/userOffFront',
//         name: 'userOffFront',
//         component: Offfront,
//         meta: {
//             title: '销户-正面面验证'
//         }
//     },
//     {
//         path: '/userOffBack',
//         name: 'userOffBack',
//         component: Offback,
//         meta: {
//             title: '销户-反面验证'
//         }
//     },
//     {
//         path: '/userOffVideo',
//         name: 'userOffVideo',
//         component: Offvideo,
//         meta: {
//             title: '销户-视频验证'
//         }
//     },
//     {
//         path: '/userOffCharge',
//         name: 'userOffCharge',
//         component: Offcharge,
//         meta: {
//             title: '销户-欠费充值'
//         }
//     },

    // begin-/红包

    {
        path: '/red',
        name: 'red',
        component: Red,
        meta: {
            title: '红包封面领取'
            // weixinAuth: true
        }
    },
    {
        path: '/redimg',
        name: 'redimg',
        component: Redimg,
        meta: {
            title: '红包封面领取'
            // weixinAuth: true
        }
    },
    // 稻米卡领红包
    {
        path: '/ricered',
        name: 'ricered',
        component: Ricered,
        meta: {
            title: '红包封面领取'
            // weixinAuth: true
        }
    },
    // end-红包


// begin-补换卡

    {
        path: '/cardOrder',
        name: 'cardOrder',
        component: Cardorder,
        meta: {
            title: '补换卡',
            snsapiBase: true
        }
    },

    {
        path: '/cardfront',
        name: 'cardfront',
        component: Cardfront,
        meta: {
            title: '正面校验-补换卡'
        }
    },

    {
        path: '/cardback',
        name: 'cardback',
        component: Cardback,
        meta: {
            title: '反面校验-补换卡'
        }
    },
    {
        path: '/cardvideo',
        name: 'cardvideo',
        component: Cardvideo,
        meta: {
            title: '视频验证-补换卡'
        }
    },
    {
        path: '/contactAddress',
        name: 'contactAddress',
        component: ContactAddress,
        meta: {
            title: '邮寄地址'
        }
    },
// end-补换卡


    // begin--电信靓号

    // {
    //     path: '/flowprod',
    //     name: 'flowprod',
    //     component: Flowprod,
    //     meta: {
    //         title: '套餐介绍'
    //     }
    // },
    // {
    //     path: '/flowtnum',
    //     name: 'flowtnum',
    //     component: Flowtnum,
    //     meta: {
    //         title: '选择号码'
    //     }
    // },
    // {
    //     path: '/flowadd',
    //     name: 'flowadd',
    //     component: Flowadd,
    //     meta: {
    //         title: '填写地址'
    //     }
    // },

    // end--电信靓号





    // // begin--激活类型选择-分流
    // {
    //     path: '/realcheck',
    //     name: 'realcheck',
    //     component: Realcheck,
    //     meta: {
    //         title: '实名制补录'
    //     }
    // },
    // {
    //     path: '/realfront',
    //     name: 'realfront',
    //     component: Realfront,
    //     meta: {
    //         title: '身份证正面验证'
    //     }
    // },
    // {
    //     path: '/realback',
    //     name: 'realback',
    //     component: Realback,
    //     meta: {
    //         title: '身份证反面验证'
    //     }
    // },{
    //     path: '/realhand',
    //     name: 'realhand',
    //     component: Realhand,
    //     meta: {
    //         title: '身份证反面验证'
    //     }
    // },
    // {
    //     path: '/realvideo',
    //     name: 'realvideo',
    //     component: Realvideo,
    //     meta: {
    //         title: '视频验证'
    //     }
    // },
    // end--激活类型选择-分流



    // begin--激活类型选择-分流
    // {
    //     path: '/gettype',
    //     name: 'gettype',
    //     component: Gettype,
    //     meta: {
    //         title: '开卡激活'
    //     }
    // },
    // end--激活类型选择-分流



    // begin--尾号查询----电信靓号
    {
        path: '/numstart',
        name: 'numstart',
        component: Numstart,
        meta: {
            title: '靓号大放送'
        }
    },
    {
        path: '/numtail',
        name: 'numtail',
        component: Numtail,
        meta: {
            title: '选靓号'
        }
    },
    {
        path: '/prodtail',
        name: 'prodtail',
        component: Prodtail,
        meta: {
            title: '套餐'
        }
    },
    {
        path: '/addtail',
        name: 'addtail',
        component: Addtail,
        meta: {
            title: '填写地址'
        }
    },

    // end----尾号查询----电信靓号








    // // BEGIN-过户
    // {
    //     path: '/transferOrder',
    //     name: 'transferOrder',
    //     component: TransferOrder,
    //     meta: {
    //         title: '过户'
    //     }
    // },
    // {
    //     path: '/transferFront',
    //     name: 'transferFront',
    //     component: TransferFront,
    //     meta: {
    //         title: '正面验证-过户'
    //     }
    // },
    // {
    //     path: '/transferBack',
    //     name: 'transferBack',
    //     component: TransferBack,
    //     meta: {
    //         title: '反面验证-过户'
    //     }
    // },
    // {
    //     path: '/transferVideo',
    //     name: 'transferVideo',
    //     component: TransferVideo,
    //     meta: {
    //         title: '视频验证-过户'
    //     }
    // },
    // {
    //     path: '/newTransferIndex',
    //     name: 'newTransferIndex',
    //     component: NewTransferIndex,
    //     meta: {
    //         title: '新客户订单验证-过户'
    //     }
    // },
    // {
    //     path: '/newTransferFront',
    //     name: 'newTransferFront',
    //     component: NewTransferFront,
    //     meta: {
    //         title: '新客户正面验证-过户'
    //     }
    // },
    // {
    //     path: '/newTransferBack',
    //     name: 'newTransferBack',
    //     component: NewTransferBack,
    //     meta: {
    //         title: '新客户反面验证-过户'
    //     }
    // }, {
    //     path: '/newTransferVideo',
    //     name: 'newTransferVideo',
    //     component: NewTransferVideo,
    //     meta: {
    //         title: '新客户视频验证-过户'
    //     }
    // },

    // end-过户

    {
        path: '/idqueryorder',
        name: 'idqueryorder',
        component: Idqueryorder,
        meta: {
            title: '名下号码查询'
        }
    },

    {
        path: '/idqueryfront',
        name: 'idqueryfront',
        component: Idqueryfront,
        meta: {
            title: '正面校验-名下号码查询'
        }
    },
    {
        path: '/idqueryback',
        name: 'idqueryback',
        component: Idqueryback,
        meta: {
            title: '反面面校验-名下号码查询'
        }
    },

    {
        path: '/idqueryvideo',
        name: 'idqueryvideo',
        component: Idqueryvideo,
        meta: {
            title: '视频验证-名下号码查询'
        }
    },


    {
        path: '/idquerylist',
        name: 'idquerylist',
        component: Idquerylist,
        meta: {
            title: '查询结果'
        }
    },



    {
        path: '/img',
        name: 'img',
        component: Img,
        meta: {
            title: '图片压缩'
        }
    },
    {
        path: '/temporary',
        name: 'temporary',
        component: Temporary,
        meta: {
            title: '临时首页'
        }
    },
    // begin--补换卡
    {
        path: '/replaceorder',
        name: 'replaceorder',
        component: Replaceorder,
        meta: {
            title: '补换卡',
            // snsapiBase: true
        }
    },
    {
        path: '/replacefront',
        name: 'replacefront',
        component: Replacefront,
        meta: {
            title: '补换卡-身份证正面校验',
            // snsapiBase: true
        }
    }, {
        path: '/replaceback',
        name: 'replaceback',
        component: Replaceback,
        meta: {
            title: '补换卡-身份证反面校验',
            // snsapiBase: true
        }
    }, {
        path: '/replacevideo',
        name: 'replacevideo',
        component: Replacevideo,
        meta: {
            title: '补换卡-视频认证',
            // snsapiBase: true
        }
    },
    // end--补换卡
    // {
    //     path: '/actorder',
    //     name: 'actorder',
    //     component: Actorder,
    //     meta: {
    //         title: '预开户激活',
    //         snsapiBase: true
    //     }
    // },

    // {
    //     path: '/actfront',
    //     name: 'actfront',
    //     component: Actfront,
    //     meta: {
    //         title: '身份证正面验证'
    //     }
    // },  {
    //     path: '/acttmallfront',
    //     name: 'acttmallfront',
    //     component: Acttmallfront,
    //     meta: {
    //         title: '身份证正面校验'
    //     }
    // },
    {
        path: '/ordercharge',
        name: 'ordercharge',
        component: Ordercharge,
        meta: {
            title: '稻米-订单支付'
        }
    },
    {
        path: '/riceorder',
        name: 'riceorder',
        component: Riceorder,
        meta: {
            title: '稻米订单'
        }
    },  {
        path: '/riceordersea',
        name: 'riceordersea',
        component: Riceordersea,
        meta: {
            title: '订单查询'
        }
    },
    {
        path: '/actricefront',
        name: 'actricefront',
        component: Actricefront,
        meta: {
            title: '稻米-身份证正面校验'
        }
    },



    // {
    //     path: '/actback',
    //     name: 'actback',
    //     component: Actback,
    //     meta: {
    //         title: '身份证反面验证'
    //     }
    // },    {
    //     path: '/acthand',
    //     name: 'acthand',
    //     component: Acthand,
    //     meta: {
    //         title: '手持ICCID验证'
    //     }
    // },  {
    //     path: '/acttmallhand',
    //     name: 'acttmallhand',
    //     component: Acttmallhand,
    //     meta: {
    //         title: '手持ICCID验证'
    //     }
    // },

    {
        path: '/actgroupphoto',
        name: 'actgroupphoto',
        component: Actgroupphoto,
        meta: {
            title: '合照验证'
        }
    },
    {
        path: '/tamllgroupphoto',
        name: 'tamllgroupphoto',
        component: Tmallgroupphoto,
        meta: {
            title: '合照验证'
        }
    },


    {
        path: '/acttmallback',
        name: 'acttmallback',
        component: Acttmallback,
        meta: {
            title: '身份证反面校验'
        }
    },  {
        path: '/actriceback',
        name: 'actriceback',
        component: Actriceback,
        meta: {
            title: '稻米-身份证反面校验'
        }
    },

    // {
    //     path: '/actvideo',
    //     name: 'actvideo',
    //     component: Actvideo,
    //     meta: {
    //         title: '视频认证'
    //     }
    // },
    // {
    //     path: '/acttmallvideo',
    //     name: 'acttmallvideo',
    //     component: Acttmallvideo,
    //     meta: {
    //         title: '视频验证'
    //     }
    // },
    {
        path: '/actricevideo',
        name: 'actricevideo',
        component: Actricevideo,
        meta: {
            title: '稻米-视频验证'
        }
    },

    // {
    //     path: '/actcharge',
    //     name: 'actcharge',
    //     component: Actcharge,
    //     meta: {
    //         title: '开户充值',
    //         snsapiBase: true
    //     }
    // },
    {
        path: '/charge/',
        name: 'charge',
        component: Charge,
        meta: {
            title: '话费充值',
            // snsapi_base:''
            snsapiBase: true
        }
    },
    {
        path: '/chargedis/',
        name: 'chargedis',
        component: Chargedis,
        meta: {
            title: '话费充值',
            // snsapi_base:''
            snsapiBase: true
        }
    },
    {
        path: '/charge/super',
        name: 'super',
        component: Super,
        meta: {
            title: '话费充值',
            // snsapi_base:''
            snsapiBase: true
        }
    },

    {
        path: '/rechargeorder/',
        name: 'rechargeorder',
        component: Rechargeorder,
        meta: {
            title: '充值记录',
            requireAuth: true

        }
    },
    {
        path: '/shop',
        name: 'shop',
        component: Shop,
        meta: {
            title: '入网须知'
        }
    },
    {
        path: '/baseinfo',
        name: 'baseinfo',
        component: Baseinfo,
        meta: {
            title: '我的基本信息',
            requireAuth: true

        }
    },
    // begin--电信靓号

    {
        path: '/prodctc',
        name: 'prodctc',
        component: Prodctc,
        meta: {
            title: '套餐介绍'
        }
    },
    {
        path: '/numctc',
        name: 'numctc',
        component: Numctc,
        meta: {
            title: '选择号码'
        }
    },


    {
        path: '/addctc',
        name: 'addctc',
        component: Addctc,
        meta: {
            title: '填写地址'
        }
    },

    // end--电信靓号




    {
        path: '/selectnum',
        name: 'selectnum',
        component: Selectnum,
        meta: {
            title: '选择号码'
        }
    },
    {
        path: '/selectprod',
        name: 'selectprod',
        component: Selectprod,
        meta: {
            title: '选择礼包'
        }
    },
    {
        path: '/address',
        name: 'address',
        component: Address,
        meta: {
            title: '填写地址'
        }
    },
    {
        path: '/bill',
        name: 'bill',
        component: Bill,
        meta: {
            title: '月账单',
            requireAuth: true
        }
    },
    // Pay history
    {
        path: '/prodused',
        name: 'prodused',
        component: Prodused,
        meta: {
            title: '套餐使用量',
            // requireAuth: true
        }
    },
    {
        path: '/payhistory',
        name: 'payhistory',
        component: Payhistory,
        meta: {
            title: '充值记录',
            // requireAuth: true
        }
    },
    {
        path: '/detail',
        name: 'detail',
        component: Detail,
        meta: {
            title: '详单查询',
            requireAuth: true
        }
    }, {
        path: '/interim',
        name: 'interim',
        component: Interim,
        meta: {
            title: '身份验证',
            requireAuth: true
        }
    },
    {
        path: '/query',
        name: 'query',
        component: Query,
        meta: {
            title: '查询功能',
            // requireAuth: true
        }
    },
    {
        path: '/manage',
        name: 'manage',
        component: Manage,
        meta: {
            title: '业务办理',
            // requireAuth: true
        }
    },
    {
        path: '/voice',
        name: 'voice',
        component: Voice,
        meta: {
            title: '语音详单',
            requireAuth: true
        }
    },
    {
        path: '/mess',
        name: 'mess',
        component: Mess,
        meta: {
            title: '短信详单',
            requireAuth: true
        }
    }, {
        path: '/mms',
        name: 'mms',
        component: Mms,
        meta: {
            title: '彩信详单',
            requireAuth: true
        }
    },
    {
        path: '/web',
        name: 'web',
        component: Web,
        meta: {
            title: '流量详单',
            requireAuth: true
        }
    },
    {
        path: '/remind',
        name: 'remind',
        component: Remind
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            title: '长城移动',
            // requireAuth: true
        }
    },
    {
        path: '/fee',
        name: 'fee',
        component: Fee,
        meta: {
            title: '实时话费',
            requireAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '服务密码登录',
            weixinAuth: true
        }
    },
    {
        path: '/codelogin',
        name: 'codelogin',
        component: Code,
        meta: {
            title: '验证码登录',
            // requireAuth: true,
            weixinAuth: true
        }
    },
    {
        path: '/loginAll',
        name: 'loginAll',
        component: loginAll,
        meta: {
            title: '登录',
            // requireAuth: true,
            weixinAuth: true
        }
    },
    {
        path: '/reset',
        name: 'reset',
        component: Reset,
        meta: {
            title: '服务密码重置',
            // requireAuth: true,
            weixinAuth: true
        }
    },
    // forget
    {
        path: '/forget',
        name: 'forget',
        component: Forget,
        meta: {
            title: '忘记密码',
            // requireAuth: true
        }
    },
    {
        path: '/user',
        name: 'user',
        component: User,
        meta: {
            title: '会员中心',
            requireAuth: true
        }
    },
    {
        path: '/myProd',
        name: 'myProd',
        component: MyProd,
        meta: {
            title: '我的套餐',
            // requireAuth: true

        }
    },
    {
        path: '/userRes',
        name: 'userRes',
        component: UserRes,
        meta: {
            title: '套餐使用量',
            requireAuth: true
        }
    },
    {
        path: '/cart',
        name: 'cart',
        component: Cart,
        meta: {
            title: '购物车'
        }
    },
    {
        path: '/goods',
        name: 'goods',
        component: Goods,
        meta: {
            title: '商品详情'
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            title: 'about',
            requireAuth: true,
        }
    },
    {
        path: '/club',
        name: 'club',
        component: Club,
        meta: {
            title: '会员权益购买',
            requireAuth: true
        }
    },
    {
        path: '/ricenum',
        name: 'ricenum',
        component: Ricenum,
        meta: {
            title: '选择号码'
        }
    },
    {
        path: '/riceprod',
        name: 'riceprod',
        component: Riceprod,
        meta: {
            title: '选择套餐'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: '领卡登记',
            snsapiBase: true
        }
    },
    {
        path: '/ricecard',
        name: 'ricecard',
        component: Ricecard,
        meta: {
            title: '验证稻米卡'
        }
    },
    {
        path: '/custcharge',
        name: 'custcharge',
        component: Custcharge,
        meta: {
            title: '话费预存'
        }
    }, {
        path: '/shell',
        name: 'shell',
        component: Shell,
        meta: {
            title: '领取手机壳'
        }
    },
    , {
        path: '/qifest',
        name: 'qifest',
        component: Qifest,
        meta: {
            title: '稻米卡礼包'
        }
    },
    {
        path: '/recharge',
        name: 'recharge',
        component: Recharge,
        meta: {
            title: '手机充值',
            snsapiBase: true
        }
    },
    {
        path: '/gift',
        name: 'gift',
        component: Gift,
        meta: {
            title: '充值赠礼',
        }
    },{
        path: '/payConfirm',
        name: 'payConfirm',
        component: PayConfirm,
        meta: {
            title: '支付确认',
        }
    },{
        path: '/exchange',
        name: 'exchange',
        component: Exchange,
        meta: {
            title: '兑换页面',
        }
    },{
        path: '/exchangeOrder',
        name: 'exchangeOrder',
        component: ExchangeOrder,
        meta: {
            title: '兑换记录',
            requireAuth: true
        }
    },
    ,{
        path: '/number_market',
        name: 'number_market',
        component: NumberMarket,
        meta: {
            title: '号码商城',
            snsapiBase: true
        }
    }
    ,{
        path: '/numDetail',
        name: 'numDetail',
        component: NumDetail,
        meta: {
            title: '号码详情'
        }
    },
    ,{
        path: '/orderPay',
        name: 'orderPay',
        component: OrderPay,
        meta: {
            title: '订单支付'
        }
    } ,{
        path: '/addressList',
        name: 'addressList',
        component: AddressList,
        meta: {
            title: '收货地址'
        }
    },{
        path: '/addressEdit',
        name: 'addressEdit',
        component: AddressEdit,
        meta: {
            title: '地址编辑'
        }
    },
    {
        path: '/cartList',
        name: 'cartList',
        component: CartList,
        meta: {
            title: '购物车'
        }
    },
    {
        path: '/numCollect',
        name: 'numCollect',
        component: NumCollect,
        meta: {
            title: '我的收藏'
        }
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
            title: '号码筛选'
        }
    },
    {
        path: '/numUser',
        name: 'numUser',
        component: NumUser,
        meta: {
            title: '个人中心'
        }
    },
    {
        path: '/myOrder',
        name: 'myOrder',
        component: MyOrder,
        meta: {
            title: '我的订单',
            snsapiBase: true
        }
    },
    // {
    //     path: '/selProd',
    //     name: 'selProd',
    //     component: SelectProd,
    //     meta: {
    //         title: '套餐优选',
    //         snsapiBase: true
    //     }
    // },    {
    //     path: '/selSearch',
    //     name: 'selSearch',
    //     component: SelSearch,
    //     meta: {
    //         title: '筛选'
    //     }
    // },
    {
        path: '/wxActive',
        name: 'wxActive',
        component: WxActive,
        meta: {
            title: '开卡激活'
        }
    },
    {
        path: '/wxCustInfo',
        name: 'wxCustInfo',
        component: WxCustInfo,
        meta: {
            title: '开户人信息'
        }
    },{
        path: '/wxHand',
        name: 'wxHand',
        component: WxHand,
        meta: {
            title: '手持ICCID'
        }
    },
    {
        path: '/wxVideo',
        name: 'wxVideo',
        component: WxVideo,
        meta: {
            title: '活体验证'
        }
    },
    {
        path: '/changeOffer',
        name: 'changeOffer',
        component: ChangeOffer,
        meta: {
            title: '套餐变更',
            requireAuth: true
        }
    },
    {
        path: '/changePkg',
        name: 'changePkg',
        component: ChangePkg,
        meta: {
            title: '增值套餐',
            requireAuth: true
        }
    },
    {
        path: '/changeFlow',
        name: 'changeFlow',
        component: ChangeFlow,
        meta: {
            title: '流量包',
            requireAuth: true
        }
    }
    //代理商识人
    , {
        path: '/agentRealFront',
        name: 'agentRealFront',
        component: AgentRealFront,
        meta: {
            title: '代理商实名认证-正面验证',
        }
    }
    , {
        path: '/agentRealBack',
        name: 'agentRealBack',
        component: AgentRealBack,
        meta: {
            title: '代理商实名认证-反面验证',

        }
    }
    , {
        path: '/agentRealVideo',
        name: 'agentRealVideo',
        component: AgentRealVideo,
        meta: {
            title: '代理商实名认证-视频验证',

        }
    } ,
    {
        path: '/groupReal',
        name: 'groupReal',
        component: GroupReal,
        meta: {
            title: '集团使用人认证-手机号验证',
        }
    } ,
    {
        path: '/groupRealFront',
        name: 'groupRealFront',
        component: GroupRealFront,
        meta: {
            title: '集团使用人认证-身份证正面验证',
        }
    } ,
    {
        path: '/groupRealBack',
        name: 'groupRealBack',
        component: GroupRealBack,
        meta: {
            title: '集团使用人认证-身份证反面验证',
        }
    } ,
    {
        path: '/groupRealVideo',
        name: 'groupRealVideo',
        component: GroupRealVideo,
        meta: {
            title: '集团使用人认证-视频认证',
        }
    } ,
    {
        path: '/ringIndex',
        name: 'ringIndex',
        component: RingIndex,
        meta: {
            title: '炫铃',
            requireAuth: true

        }
    }
    , {
        path: '/ringPlay',
        name: 'ringPlay',
        component: RingPlay,
        meta: {
            title: '炫铃-播放',

        }
    }
    , {
        path: '/ringList',
        name: 'ringList',
        component: RingList,
        meta: {
            title: '炫铃-列表',
        }
    }
    // , {
    //     path: '/tips',
    //     name: 'tips',
    //     component: Tips,
    //     meta: {
    //         title: '开卡激活',
    //     }
    // }
    // , {
    //     path: '/examineResult',
    //     name: 'examineResult',
    //     component: ExamineResult,
    //     meta: {
    //         title: '开卡审核结果',
    //     }
    // }
    // , {
    //     path: '/newOpenOrder',
    //     name: 'newOpenOrder',
    //     component: NewOpenOrder,
    //     meta: {
    //         title: '开卡激活-订单校验',
    //     }
    // }
    // , {
    //     path: '/newOpenFront',
    //     name: 'newOpenFront',
    //     component: NewOpenFront,
    //     meta: {
    //         title: '开卡激活-身份证正面校验',
    //     }
    // }
    // , {
    //     path: '/newOpenBack',
    //     name: 'newOpenBack',
    //     component: NewOpenBack,
    //     meta: {
    //         title: '开卡激活-身份证反面校验',
    //     }
    // }
    // , {
    //     path: '/newOpenVideo',
    //     name: 'newOpenVideo',
    //     component: NewOpenVideo,
    //     meta: {
    //         title: '开卡激活-活体校验',
    //     }
    // }
    // ,
    // {
    //     path: '/newOpenCharge',
    //     name: 'newOpenCharge',
    //     component: NewOpenCharge,
    //     meta: {
    //         title: '开户充值',
    //         snsapiBase: true
    //     }
    // },
    // , {
    //     path: '/newOpenNotPayFront',
    //     name: 'newOpenNotPayFront',
    //     component: NewOpenNotPayFront,
    //     meta: {
    //         title: '开卡激活-身份证正面校验',
    //     }
    // }
    // , {
    //     path: '/newOpenNotPayBack',
    //     name: 'newOpenNotPayBack',
    //     component: NewOpenNotPayback,
    //     meta: {
    //         title: '开卡激活-身份证反面校验',
    //     }
    // }
    // , {
    //     path: '/newOpenNotPayVideo',
    //     name: 'newOpenNotPayVideo',
    //     component: NewOpenNotPayVideo,
    //     meta: {
    //         title: '开卡激活-活体校验',
    //     }
    // }
    ,{
        path: '/changeCardOrder',
        name: 'changeCardOrder',
        component: ChangeCardOrder,
        meta: {
            title: '补换卡订单',
            snsapiBase: true
        }
    }
    ,{
        path: '/userTransferOrder',
        name: 'userTransferOrder',
        component: UserTransferOrder,
        meta: {
            title: '过户订单'
        }
    },
    {
        path: '/largeSpin',
        name: 'largeSpin',
        component: LargeSpin,
        meta: {
            title: '大转盘',
            requireAuth: true
        }
    }

];

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({routes});

router.beforeEach(async (to, from, next) => {
    let ua = window.navigator.userAgent.toLowerCase();
    // //使手机充值可以h5访问
    // if (ua.indexOf('micromessenger') == -1  && to.name == "recharge") {
    //     next();
    //     return;
    // }
    if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)){
        if (ua.indexOf('micromessenger') == -1) {
            alert("请使用移动端微信浏览器打开")
            window.location.href  =  window.location.origin + "/#"
            return;
        }
    }else {
        alert("请使用移动端微信浏览器打开")
        window.location.href  =  window.location.origin + "/#"
        return;
    }
    const title = to.meta && to.meta.title;
    let token_cookie = getCookie('token')
    if (title) {
        document.title = title;
    }
    if (!to.query.t){
        let query = to.query;
        query.t = new Date().getTime()
        next({
            path: to.path,
            query: query
        });
    }
    // 使用微信授权登录的方式  该步骤暂时隐去
    if (to.matched.some(r => r.meta.requireAuth)) {
        if (token_cookie) {
            next();
        } else {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
                // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }
    } else {
        next();
    }
    // 微信授权登录
    if (to.matched.some(recode => recode.meta.weixinAuth)) {
        console.log('wxuser===')
        let   wxuserinfo =  store.state.login.wxuserinfo
        console.log(wxuserinfo)
        // this.$store.state.login.wxuserinfo
        if(Object.keys(wxuserinfo).length !== 0){
            next()
            return
        }
        // if(Object.keys(localStorage_vuex).length !== 0){
        //     if (localStorage_vuex.root.login.nickname) {
        //         console.log('获取到了localStorage_vuex')
        //         store.commit('login/setUserInfo', localStorage_vuex.login.wxuserinfo)
        //         next()
        //         return
        //     }
        // }
        // if(localStorage_weixin){
        //     if (localStorage_weixin.nickname) {
        //         console.log('获取到了localStorage_weixin')
        //         store.commit('login/setUserInfo', localStorage_weixin)
        //         next()
        //         return
        //     }
        // }
        const code = getUrlParams('code') // 截取url上的code ,可能没有,则返回''空字符串
        if (typeof code == 'undefined') {
            console.log(window.location.origin)
            console.log(to.fullPath)
            // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
            // let redirectUrl = window.location.href
            let redirectUrl = window.location.origin + "/#" + to.fullPath
            redirectUrl = encodeURIComponent(redirectUrl)
            console.log(redirectUrl)
            const appid = 'wxe3d25b8faf11a4e0'//10043
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
        }
        else {
            console.log('code:  '+code)
            let res  =  await  new Promise(function(resolve, reject){
                let openid = oauthbycode(code)
                resolve(openid)
            });

            // let res = oauthbycode(code) // 获取用户信息,后端可首先通过cookie,session等判断,没有信息则通过code获取
            console.log('oauthbycode-resres:::::')
            console.log(res)
            if (res.status === 200) {
                if(res.data.code==0){
                    console.log('coming')
                    let  wxuser = {
                        openid:res.data.openid,
                        nickname:res.data.nickname,
                        headimgurl:res.data.headimgurl
                    }
                    // setCookie = ('wxuser',value,6404800)
                    // // this.$store.commit('productlist/getStoreListShow', data.page.list)
                    setCookie('openid',res.data.openid,6404800)
                    setCookie('nickname',res.data.nickname,6404800)
                    setCookie('headimgurl',res.data.headimgurl,6404800)
                    store.commit('login/setUserInfo', res.data)
                    // weixinStorage.save(res.data)
                    // console.log(res.data.nickname)
                    window.location.href  =  window.location.origin + "/#" + to.fullPath
                    // next()
                }
                else if(res.data.code==40163){
                    console.log(window.location.origin)
                    console.log(to.fullPath)
                    // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
                    let redirectUrl = window.location.origin + "/#" + to.fullPath
                    redirectUrl = encodeURIComponent(redirectUrl)
                    console.log(redirectUrl)
                    const appid = 'wxe3d25b8faf11a4e0'//10043
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
                }
            } else {
                // 上面的获取用户信息接口,如果cookie,session拿不到用户信息,且传递的code为空,则跳转到微信授权页面
                if (res.status === 202) {
                    console.log(window.location.origin)
                    console.log(to.fullPath)
                    // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
                    let redirectUrl = window.location.origin + "/#" + to.fullPath
                    redirectUrl = encodeURIComponent(redirectUrl)
                    console.log(redirectUrl)
                    const appid = 'wxe3d25b8faf11a4e0'//10043
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
                }
            }

        }
        // 返回用户信息
        // {"openid":"o5Tqx0eAhGBW2einiqTyxaqT-l7g","nickname":"许仙","sex":1,"language":"zh_CN","city":"杭州","province":"浙江","country":"中国","headimgurl":"http://thirdwx.qlogo.cn/mmopen/vi_32/ibhtj6joaSEaaR3wplCGwPHrHA0m4ZBicWcAgzE0qqm12s4icVSdRwKGzefIrvsmVhM0bDQ1f1ibGGkzyoic8Gx1FYw/132","privilege":[]}
    } else {
        next()
    }
    // 微信 静默授权  snsapiBase    {msg: "success", code: 0, openid: "oanVsuOLU5sfaNVldrIz91xlzWLE", nickname: "许仙", headimgurl: "http://thirdwx.qlogo.cn/mmopen/vi_32/uKo8NYAN7FQOO…UkAART7xoJbdKLXG4dIBS9myYPErb5TRqgRicBpKqiafw/132"}
    if (to.matched.some(recode => recode.meta.snsapiBase)) {
        let userId = to.query.userId;
        if (userId){
            next()
            return
        }
        console.log('openid===')
        console.log(getCookie('openid'))
        if (getCookie('openid')) {
            next()
            return
        }
        // if(Object.keys(localStorage_vuex).length !== 0){
        //     if (localStorage_vuex.root.login.nickname) {
        //         console.log('获取到了localStorage_vuex')
        //         store.commit('login/setUserInfo', localStorage_vuex.login.wxuserinfo)
        //         next()
        //         return
        //     }
        // }
        // if(localStorage_weixin){
        //     if (localStorage_weixin.nickname) {
        //         console.log('获取到了localStorage_weixin')
        //         store.commit('login/setUserInfo', localStorage_weixin)
        //         next()
        //         return
        //     }
        // }
        const code = getUrlParams('code') // 截取url上的code ,可能没有,则返回''空字符串
        if (typeof code == 'undefined') {
            console.log(window.location.origin)
            console.log(to.fullPath)
            // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
            // let redirectUrl = window.location.href
            let redirectUrl = window.location.origin + "/#" + to.fullPath
            redirectUrl = encodeURIComponent(redirectUrl)
            console.log(redirectUrl)
            const appid = 'wxe3d25b8faf11a4e0'//10043
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
        }
        else {
            console.log('code:  '+code)
            let res  =  await  new Promise(function(resolve, reject){
                let openid = oauthbycode(code)
                resolve(openid)
            });

            // let res = oauthbycode(code) // 获取用户信息,后端可首先通过cookie,session等判断,没有信息则通过code获取
            console.log('oauthbycode-resres:::::')
            console.log(res)
            if (res.status === 200) {
                if(res.data.code==0){
                    console.log('coming')
                    // let  wxuser = {
                    //     openid:res.data.openid
                    //     // nickname:res.data.nickname,
                    //     // headimgurl:res.data.headimgurl
                    // }
                    let   openid =   res.data.openid
                    let   nickname =   res.data.nickname
                    let   headimgurl =   res.data.headimgurl
                    setCookie('openid',openid,6404800)
                    setCookie('nickname',nickname,6404800)
                    setCookie('headimgurl',headimgurl,6404800)
                    // // this.$store.commit('productlist/getStoreListShow', data.page.list)
                    // store.commit('login/setUserInfo', res.data)
                    // weixinStorage.save(res.data)
                    // console.log(res.data.nickname)
                    window.location.href  =  window.location.origin + "/#" + to.fullPath

                    // next()
                }
                else if(res.data.code==40163){
                    console.log(window.location.origin)
                    console.log(to.fullPath)
                    // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
                    let redirectUrl = window.location.origin + "/#" + to.fullPath
                    redirectUrl = encodeURIComponent(redirectUrl)
                    console.log(redirectUrl)
                    const appid = 'wxe3d25b8faf11a4e0'//10043
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
                }
            } else {
                // 上面的获取用户信息接口,如果cookie,session拿不到用户信息,且传递的code为空,则跳转到微信授权页面
                if (res.status === 202) {
                    console.log(window.location.origin)
                    console.log(to.fullPath)
                    // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
                    let redirectUrl = window.location.origin + "/#" + to.fullPath
                    redirectUrl = encodeURIComponent(redirectUrl)
                    console.log(redirectUrl)
                    const appid = 'wxe3d25b8faf11a4e0'//10043
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
                }
            }

        }
        // 返回用户信息
        // {"openid":"o5Tqx0eAhGBW2einiqTyxaqT-l7g","nickname":"许仙","sex":1,"language":"zh_CN","city":"杭州","province":"浙江","country":"中国","headimgurl":"http://thirdwx.qlogo.cn/mmopen/vi_32/ibhtj6joaSEaaR3wplCGwPHrHA0m4ZBicWcAgzE0qqm12s4icVSdRwKGzefIrvsmVhM0bDQ1f1ibGGkzyoic8Gx1FYw/132","privilege":[]}
    } else {
        next()
    }
});

export {
    router
};
